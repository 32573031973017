import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Ribbon = styled.div`
  &.centered {
    left: 50%;
    right: unset;
    z-index: 555;
    bottom: 0;
    transform: translate(-72px,37px);
    @media screen and (max-width: 500px) {
      transform: translate(-75px,37px);
    }
    img {
      z-index: 999;
      // transform: rotate(180deg);
      top: 0;
      left: 0;
    }
  }
  width: auto;
  height: 188px;
  right: 0;
  top: -0.5rem;
  .link-text {
    display: none;
  }
  @media screen and (max-width: 1500px) {
    right: 0;
  }
  @media screen and (max-width: 1300px) {
    right: 0;
  }
  position: absolute;
  float: left;
  margin-top: -2.1rem;
  margin-bottom: 30px;
  background-size: cover;
  color: white;
  z-index: 999;
  .inner {
    width: 80px;
    padding: 0.5rem 0;
    position: absolute;
    left: 25px;
    text-align: center;
    border-top-left-radius: 3px;
    background: #ffffff;
    &.green {
      background: ${props => props.theme.colors.highlight};
    }
  }
  .inner:before {
    height: 0;
    width: 0;
    right: -6px;
    top: 0.1px;
    border-bottom: 6px solid #ddd;
    border-right: 6px solid transparent;
  }
  .inner:before,
  .inner:after {
    content: '';
    position: absolute;
  }
  .inner:after {
    height: 0;
    width: 0;
    bottom: -29.5px;
    left: 0;
    border-left: 40px solid #ffffff;
    border-right: 40px solid #ffffff;
    border-bottom: 30px solid transparent;
  }
  .inner.green:after {
    border-left: 40px solid ${props => props.theme.colors.highlight};
    border-right: 40px solid ${props => props.theme.colors.highlight};
  }
  @media screen and (max-width: 1299px) {
    &:not(.centered) {
      opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    &.green {
      p {
        background: ${props => props.theme.colors.highlight};
        color: ${props => props.theme.colors.inverted};
      }
    }
    position: relative;
    .green {
      background: ${props => props.theme.colors.highlight};
      &:hover,
      &:hover p{
        background: ${props => props.theme.colors.secondary};
      }
    }
    margin: 0 auto 0.5rem auto;
    padding: 0;
    display: block;
    height: auto;
    width: 100%;
    left: 0;
    right: 0;
    .inner {
      width: 100%;
      left: 0;
      top: 0;
      position: relative;
      .link-text {
        display: block;
        width: 100%;
        margin: 0;
      }
    }
    .link-image {
      display: none;
    }
    // background: ${props => props.theme.colors.tertiary};
    a {
      font-family: ${props => props.theme.fonts.title};
      font-weight: 800;
      padding: 0;
      display: block;
      color: ${props => props.theme.colors.base};
    }
    &.green {
      margin-top: 1rem;
      a,
    }
    .inner {
      display: block;
    }
    .inner:after {
      border: none;
    }
  }
  
  }
`

const RibbonComponent = props => {
  return (
    <Ribbon
      className={`ribbon ${props.color} ${props.isCentered ? 'centered' : ''}`}
      style={{
        pointerEvents: props.unclickable ? 'none' : 'auto',
      }}
    >
      {props.image && props.link ? (
        <div className={`inner ${props.color}`}>
          {props.link.startsWith('/') ? (
            <Link to={props.link}>
              <Img
                className="link-image"
                fluid={props.image.fluid}
                style={{ width: '80px', margin: 0 }}
                imgStyle={{ width: '100%' }}
              />
              <p className="link-text">{props.text}</p>
            </Link>
          ) : (
            <a href={props.link}>
              <Img
                className="link-image"
                fluid={props.image.fluid}
                style={{ width: '80px', margin: 0 }}
                imgStyle={{ width: '100%' }}
              />
              <p className="link-text">{props.text}</p>
            </a>
          )}
        </div>
      ) : (
        <span className="inner">{props.text}</span>
      )}
    </Ribbon>
  )
}

export default RibbonComponent
