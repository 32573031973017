import React from 'react'
import styled from 'styled-components'

const HeaderImageContainer = styled.div`
  width: 100vw;
  height: 0;
  overflow-y: hidden;
  z-index: -1;
  position: relative;
  border-top: 0.25rem solid ${props => props.theme.colors.highlight};
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    height: 0;
  }
`

const GreenLine = props => {
  return (
    <HeaderImageContainer
      style={{
        backgroundColor: props.backgroundColor || 'transparent',
        zIndex: 333
      }}
    />
  )
}

export default GreenLine
