import React from 'react'
// import Hexagon from './Hexagon'
import '../styles/hexagon.scss'

import loadable from "@loadable/component"
const Hexagon = loadable(() => import('./Hexagon'))

const HexagonsComponent = (props) => {
  const customers = props.data
  const hexagons = customers.map((customer, i) => (
    <Hexagon
      customer={customer.node}
      key={`customer-hexagon-${customer.node.id}`}
      image={customer.node.kundenlogo}
      backgroundSize={
        customer.node.bgSize ? `${customer.node.bgSize}%` : '100%'
      }
      backgroundColor={customer.node.backgroundColor || '#eee'}
    />
  ))

  return (
    <div className={'component kundschaft'} style={{paddingTop: 0}}>
      <div className={'ui vertical aligned very basic segment'}>
        <div className="grid">
          <ul id="hexGrid">
            { hexagons }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default HexagonsComponent
