import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import RibbonComponent from '../components/Ribbon'
// import GreyLine from '../components/GreyLine'
import GreenLine from '../components/GreenLine'
import PageBody from '../components/PageBody'
import HexagonsComponent from '../components/Hexagons'
import SEO from '../components/SEO'
import TextContainer from '../components/TextContainer'

const Kontakt = ({ data }) => {
  const postNode = {
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  const { body, title, subtitle } = data.contentfulPage

  const RibbonLine = styled.div`
    border-top: 0.25rem solid ${props => props.theme.colors.highlight};
    position: absolute;
    right: -50vw;
    top: -4px;
    width: 50vw;

    @media screen and (max-width: 1299px) {
      display: none;
    }
  `

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <GreenLine backgroundColor="rgba(0,0,0,0)" />

      <Container>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </TextContainer>
        {body && <PageBody body={body} />}
      </Container>

      {/* <GreyLine backgroundColor={'rgba(0,0,0,0)'} /> */}

      <Container>
        <TextContainer>
          <RibbonComponent
            text="Ihr Logo fehlt? Klicken Sie hier."
            link={data.contentfulPage.ribbonLink || 'mailto:info@vermflex.ch'}
            image={data.contentfulPage.ribbon}
            color="green"
          />
        </TextContainer>
        <RibbonLine className="ribbon-line" />
        <HexagonsComponent data={data.allContentfulKundschaft.edges} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulKundschaft(sort: { order: DESC, fields: updatedAt }) {
      edges {
        node {
          id
          link
          name
          bgSize
          flipped
          text {
            json
          }
          kundenlogo {
            file {
              url
            }
            fluid(maxWidth: 640) {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              aspectRatio
            }
          }
          logoSmall {
            file {
              url
            }
            fluid(maxWidth: 640) {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              aspectRatio
            }
          }
        }
      }
    }
    contentfulPage(slug: { eq: "kunden" }) {
      id
      title
      subtitle
      ribbon {
        fluid {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
        }
      }
      ribbonLink
      headerImage {
        id
        fluid(
          quality: 100
          maxWidth: 1920
          resizingBehavior: NO_CHANGE
          toFormat: NO_CHANGE
        ) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
  }
`

export default Kontakt
